import axios from "axios";

import { UtilService } from "../../../shared/service/utilService";

import {
  IBookmark,
  IBookmarkCollection,
  IBookmarkCollectionQuery,
  IBookmarkShare,
  ICreateBookmark,
  ICreateBookmarkCollection,
} from "../interface/bookmark.interface";
import { IHttpResponse } from "../../../shared/interface/http-response.interface";

import { BASE_URL } from "../../../environments/environment";

export class BookmarkService {
  public utilService = new UtilService();

  async getBookmarkCollection(params?: IBookmarkCollectionQuery): Promise<IHttpResponse<IBookmarkCollection[]>> {
    const { data } = await axios.get(`${BASE_URL}/utility/bookmark/collections/`, {
      ...this.utilService.buildAuthorizationHeader(),
      params,
    });
    return data;
  }

  async getBookmarkCollectionById(id: number): Promise<IBookmarkCollection> {
    const { data } = await axios.get(
      `${BASE_URL}/utility/bookmark/collections/${id}`,
      this.utilService.buildAuthorizationHeader()
    );
    return data;
  }

  async createBookmarkCollection(payload: ICreateBookmarkCollection): Promise<IBookmarkCollection> {
    const { data } = await axios.post(
      `${BASE_URL}/utility/bookmark/collections/`,
      payload,
      this.utilService.buildAuthorizationHeader()
    );
    return data;
  }

  async removeBookmarkCollection(id: number): Promise<IBookmarkCollection> {
    const { data } = await axios.delete(
      `${BASE_URL}/utility/bookmark/collections/${id}`,
      this.utilService.buildAuthorizationHeader()
    );
    return data;
  }

  async getBookmark(params?: any): Promise<IHttpResponse<IBookmark[]>> {
    const { data } = await axios.get(`${BASE_URL}/utility/bookmark/`, {
      params,
      ...this.utilService.buildAuthorizationHeader(),
    });
    return data;
  }

  async getBookmarkById(id: number): Promise<IBookmark> {
    const { data } = await axios.get(`${BASE_URL}/utility/bookmark/${id}`, this.utilService.buildAuthorizationHeader());
    return data;
  }

  async createBookmark(payload: ICreateBookmark): Promise<IBookmark> {
    const { data } = await axios.post(
      `${BASE_URL}/utility/bookmark/`,
      payload,
      this.utilService.buildAuthorizationHeader()
    );
    return data;
  }

  async removeBookmark(id: number): Promise<IBookmark> {
    const { data } = await axios.delete(
      `${BASE_URL}/utility/bookmark/${id}`,
      this.utilService.buildAuthorizationHeader()
    );
    return data;
  }

  async exportBookmark(id: number, type: string, lang: string): Promise<IBookmarkShare> {
    const { data } = await axios.get(
      `${BASE_URL}/utility/pdf-download/${id}/?file_type=${type}&lang_code=${lang}`,
      this.utilService.buildAuthorizationHeader()
    );
    return data;
  }
}
