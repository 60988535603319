import axios from "axios";

import { BASE_URL } from "../../../environments/environment";

import {
  IChangePasswordPayload,
  ILoginPayload,
  ILoginResponse,
  IRegisterPayload,
  ISetNewPasswordPayload,
} from "../interface/auth.interface";

import { UtilService } from "../../../shared/service/utilService";

export class AuthService {
  public utilService = new UtilService();

  async login(loginPayload: ILoginPayload): Promise<ILoginResponse> {
    const { data } = await axios.post(`${BASE_URL}/auth/login/`, loginPayload);
    return data;
  }

  async register(registerPayload: IRegisterPayload) {
    const { data } = await axios.post(`${BASE_URL}/auth/register/`, registerPayload);
    return data;
  }

  async forgotPassword(email: string) {
    const { data } = await axios.post(`${BASE_URL}/auth/forget-password/`, { email });
    return data;
  }

  async changePassword(changePasswordPayload: IChangePasswordPayload) {
    const { data } = await axios.post(
      `${BASE_URL}/auth/change-password/`,
      changePasswordPayload,
      this.utilService.buildAuthorizationHeader()
    );
    return data;
  }

  async setNewPassword(setNewPasswordPayload: ISetNewPasswordPayload) {
    const { data } = await axios.post(`${BASE_URL}/auth/set-new-password/`, setNewPasswordPayload);
    return data;
  }

  async getProfile() {
    const { data } = await axios.get(`${BASE_URL}/auth/profile/`, this.utilService.buildAuthorizationHeader());
    return data;
  }

  async updateProfile(user: FormData) {
    const { data } = await axios.patch(`${BASE_URL}/auth/profile/`, user, this.utilService.buildAuthorizationHeader());
    return data;
  }

  async deleteProfile(payload: FormData) {
    // TODO password is not checking in backend.
    const { data } = await axios.delete(
      `${BASE_URL}/auth/profile/delete/`,
      this.utilService.buildAuthorizationHeader()
    );
    return data;
  }
}
