import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { AppContext } from "../../App";

import { AuthService } from "../auth/service/AuthService";
import { UtilService } from "../../shared/service/utilService";
import { ToastService } from "../../shared/service/toastService";

import { EFileType } from "../../shared/enum/file.enum";
import { EFormErrorType } from "../../shared/enum/form.enum";

import { IUser } from "../auth/interface/user.interface";

import ButtonSpinner from "../../shared/components/ButtonSpinner";

const BASE_URL = "";

type TProfileForm = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
};

const authService = new AuthService();

function Profile() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<TProfileForm>({ mode: "onChange" });

  const { t } = useTranslation();
  const { userState } = useContext<any>(AppContext);

  const [user, setUser] = userState;
  const [file, setFile] = useState<File | null>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const toastService = new ToastService();
  const utilService = new UtilService();

  const handleImageFileUpload = (e: any) => {
    const file: File = e.target.files[0];
    const type = utilService.getFileExtension(file.name);
    if (type === EFileType.PNG || type === EFileType.JPEG || type === EFileType.JPG) {
      setFile(file);
    } else {
      toastService.error(`${type} is not supported! Only png, jpg, jpeg supported`);
    }
  };

  const onSubmit = async (data: TProfileForm) => {
    try {
      setIsSaving(true);
      const formData = buildFormData(data);
      const res = await authService.updateProfile(formData);
      setUser(res);
      toastService.success(t("auth.message.profile.update.success"));
      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);
      toastService.error(error?.message || error?.data?.message || t("auth.message.profile.update.error"));
      console.error("Profile error: ", error);
    }
  };

  const buildFormData = (data: TProfileForm) => {
    const formData = new FormData();
    {
      data?.first_name && formData.append("first_name", data?.first_name);
    }
    {
      data?.last_name && formData.append("last_name", data?.last_name);
    }
    {
      data?.phone && formData.append("phone", data?.phone);
    }
    {
      file && formData.append("image", file, file.name);
    }

    return formData;
  };

  const updateForm = (data: IUser) => {
    setValue("first_name", data.first_name);
    setValue("last_name", data.last_name);
    setValue("email", data.email);
    data.phone && setValue("phone", data.phone);
    setProfileImage(data?.image);
  };

  useEffect(() => {
    if (user) {
      updateForm(user);
    }
  }, [user]);

  return (
    <>
      <div className="py-2">
        <div className="bg-white w-10/12 p-5 rounded-lg mx-auto border shadow-lg">
          <h4 className="mb-8 font-bold text-2xl">{t("auth.title.edit_profile")}</h4>
          <div className="flex flex-row flex-wrap items-center">
            <div className="md:basis-1/4 lg:basis-1/4">
              <div className="relative w-36 h-36 rounded-full mx-auto">
                <img
                  className="rounded-full w-36 h-36 border"
                  src={
                    file
                      ? utilService.previewFromFile(file)
                      : profileImage
                      ? `${BASE_URL + profileImage}`
                      : "images/default_avatar.jpg"
                  }
                />
                <div className="absolute bottom-[3px] right-3">
                  <input onChange={handleImageFileUpload} id="profile_image_uploader" type="file" hidden />
                  <label
                    htmlFor="profile_image_uploader"
                    className="cursor-pointer bg-yellow-400 rounded-full flex items-center justify-center w-8 h-8 hover:bg-yellow-500"
                  >
                    <i className="ri-camera-line text-white text-lg"></i>
                  </label>
                </div>
              </div>
            </div>
            <div className="md:basis-3/4 lg:basis-3/4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-2 gap-4 mb-5">
                  <div>
                    <label htmlFor="profile_first_name">{t("auth.label.first_name")}</label>
                    <div className="mb-2">
                      <input
                        type="text"
                        id="profile_first_name"
                        className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
                        placeholder={t("auth.placeholder.first_name")}
                        {...register("first_name", { required: "First Name is required" })}
                      />
                      {errors?.first_name?.type === EFormErrorType.REQUIRED ? (
                        <span className="text-red-600 text-xs">{t("auth.validation.first_name.required")}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div>
                    <label htmlFor="profile_last_name">{t("auth.label.last_name")}</label>
                    <div className="mb-2">
                      <input
                        type="text"
                        className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
                        placeholder={t("auth.placeholder.last_name")}
                        {...register("last_name", { required: "Last Name is required" })}
                      />
                      {errors?.last_name?.type === EFormErrorType.REQUIRED ? (
                        <span className="text-red-600 text-xs">{t("auth.validation.last_name.required")}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <label htmlFor="profile_email">{t("auth.label.email")}</label>
                  <div>
                    <input
                      disabled
                      type="text"
                      id="profile_email"
                      className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
                      placeholder={t("auth.placeholder.email")}
                      {...register("email")}
                    />
                  </div>
                </div>
                <div className="mb-5">
                  <label htmlFor="profile_phone">{t("auth.label.phone")}</label>
                  <div>
                    <input
                      type="text"
                      id="profile_phone"
                      className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
                      placeholder={t("auth.placeholder.phone")}
                      {...register("phone")}
                    />
                  </div>
                </div>
                <button
                  disabled={isSaving}
                  type="submit"
                  className="mt-5 inline cursor-pointer text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
                >
                  {isSaving ? <ButtonSpinner isLoading={isSaving} /> : <></>} {t("action.save")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
