const ShareButtonSkeleton = () => {
  return (
    <div className="relative inline-block">
      <div className="group mt-2 bg-gray-300 rounded-full w-[34px] h-[34px] shadow dark:bg-gray-700 animate-pulse">
        <div className="w-[20px] h-[20px] bg-gray-400 rounded-full mx-auto my-auto dark:bg-gray-600"></div>
      </div>
    </div>
  );
};

export default ShareButtonSkeleton;
