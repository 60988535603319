import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IAppContextState, AppContext } from "../../../App";

import { HighlightService } from "../service/HighlightService";

function Highlight() {
  const highlightService = new HighlightService();
  const { t } = useTranslation();
  const { highlightState, addOrRemoveHighlight } = useContext<IAppContextState | any>(AppContext);
  const [loading, setLoading] = useState(false);
  const [highlights, setHighlights] = highlightState;

  const getHighlight = async () => {
    try {
      setLoading(true);
      const res = await highlightService.getHighlight();
      setHighlights(res?.data ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("getBookmarkCollection error: ", error);
    }
  };

  useEffect(() => {
    getHighlight();
  }, []);
  return (
    <>
      <div>
        <h1 className="text-center font-normal text-[2rem] py-8 border-b-2 border-yellow-400 border-opacity-10 dark:text-yellow-400">
          {t("highlight.title")}
        </h1>
        <div className="my-12">
          {highlights?.length ? (
            highlights?.map((highlight: any, key: number) => (
              <span
                id={`highlight_${key}`}
                key={`highlight_${key}`}
                className="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-yellow-800 bg-yellow-100 rounded dark:bg-yellow-900 dark:text-yellow-300"
              >
                {highlight.word}
                <button
                    onClick={() => addOrRemoveHighlight(highlight.word, highlight.ayat)}
                  type="button"
                  className="inline-flex items-center p-1 ms-2 text-sm text-yellow-400 bg-transparent rounded-sm hover:bg-yellow-200 hover:text-yellow-900 dark:hover:bg-yellow-500 dark:hover:text-yellow-300"
                  data-dismiss-target="#badge-dismiss-yellow"
                  aria-label="Remove"
                >
                  <svg
                    className="w-2 h-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Remove badge</span>
                </button>
              </span>
            ))
          ) : (
            <p className="text-center dark:text-white">{t("highlight.notFound")}</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Highlight;
