import { lazy, memo, Suspense, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Dropdown, Avatar, DropdownHeader, DropdownItem } from "flowbite-react";

import { ELanguageCode } from "../enum/language.enum";

import { StorageService } from "../service/storageService";

import { AppContext, IAppContextState } from "../../App";

import { IUser } from "../../modules/auth/interface/user.interface";

const GoogleTranslate = lazy(() => import("./GoogleTranslate"));

declare global {
  interface Window {
    google?: any;
    googleTranslateElementInit: () => void;
  }
}

const Navbar = memo(function Navbar({ children }: any) {
  const storageService = new StorageService();
  const { langCodeState, userState, tokenState } = useContext<IAppContextState | any>(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [user, setUser] = userState as [IUser | null, React.Dispatch<React.SetStateAction<IUser | null>>];
  const [token, setToken] = tokenState;
  const [langCode, setLangCode] = langCodeState;

  const changeLanguageCode = (code: ELanguageCode) => {
    if (code === ELanguageCode.URDU) {
      window.open("https://api.quranrt.org/media/book_pdfs/Al-Quran_Urdu_Translation.pdf", "_blank");
    }
    storageService.setLanguageCode(code);
    setLangCode(code);
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    storageService.clearUserToken();
  };

  return (
    <div className="flex items-center justify-between p-4 border bg-white dark:bg-gray-800">
      <div>{children}</div>
      <div>
        <div className="flex md:order-2  justify-end gap-3">
          <div>
            <Suspense fallback={<div>Loading...</div>}>
              <GoogleTranslate />
            </Suspense>
          </div>

          <div className="flex items-start">
            <div className="mr-2">
              <select
                onChange={(e: any) => changeLanguageCode(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-400 focus:border-yellow-400 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-500 dark:focus:border-yellow-400"
              >
                <option selected={langCode === ELanguageCode.ENGLISH} value={ELanguageCode.ENGLISH}>
                  English
                </option>
                <option selected={langCode === ELanguageCode.BANGLA} value={ELanguageCode.BANGLA}>
                  বাংলা
                </option>
                <option selected={langCode === ELanguageCode.URDU} value={ELanguageCode.URDU}>
                  Urdu
                </option>
              </select>
            </div>
            {user ? (
              <Dropdown
                arrowIcon={true}
                inline
                label={
                  <Avatar
                    alt="User settings"
                    img={user?.image ?? "/images/default_avatar.jpg"}
                    rounded
                    onError={(e) => {
                      (e.target as HTMLImageElement).src = "/images/default_avatar.jpg";
                    }}
                  />
                }
              >
                <DropdownHeader>
                  <span className="block text-sm">{user?.first_name + " " + user?.last_name}</span>
                  <span className="block truncate text-sm font-medium">{user?.email}</span>
                </DropdownHeader>
                <DropdownItem onClick={() => navigate("/profile")}>Profile</DropdownItem>
                {/* <DropdownItem onClick={() => navigate("/profile/change-password")}>Change Password</DropdownItem> */}
                <DropdownItem onClick={() => navigate("/profile/delete")}>Delete Profile</DropdownItem>
                <DropdownItem onClick={() => logout()}>Sign out</DropdownItem>
              </Dropdown>
            ) : (
              <button
                onClick={() => navigate("/auth/login")}
                type="button"
                className="text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
              >
                {t("auth.action.login")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Navbar;
