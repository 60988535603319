import React, { createContext, useEffect, useState } from "react";
import { RouterProvider } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import "flowbite/dist/flowbite";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { router } from "./routes/routes";
import { ELanguageCode } from "./shared/enum/language.enum";
import { AuthService } from "./modules/auth/service/AuthService";
import { StorageService } from "./shared/service/storageService";
import { SurahService } from "./modules/home/service/SurahService";
import { IBookPagination } from "./modules/books/interface/book.interface";
import { ISurahPagination } from "./modules/home/interface/surah.interface";
import {
  IBookmark,
  IBookmarkCollection,
  IBookmarkCollectionQuery,
} from "./modules/bookmark/interface/bookmark.interface";
import { INote } from "./modules/home/interface/note.interface";
import { IUser } from "./modules/auth/interface/user.interface";
import { AuthProvider } from "./shared/guards/AuthProvider";
import { BookmarkService } from "./modules/bookmark/service/BookmarkService";
import { IHighlight } from "./modules/highlight/interface/highlight.interface";
import { HighlightService } from "./modules/highlight/service/HighlightService";
import { NoteService } from "./modules/bookmark/service/NoteService";

export function darkToggle(isActiveDarkMode: boolean) {
  if (isActiveDarkMode) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
}

export interface IAppContextState {
  tokenState: [string | null, React.Dispatch<React.SetStateAction<string | null>>];
  userState: [IUser | null, React.Dispatch<React.SetStateAction<IUser | null>>];
  isOpenSidebarState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  isActiveDarkModeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  isCollapsedSidebarState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  isReadingModeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  loadingSurahState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  langCodeState: [ELanguageCode, React.Dispatch<React.SetStateAction<ELanguageCode>>];
  bookState: [IBookPagination, React.Dispatch<React.SetStateAction<IBookPagination>>];
  surahState: [ISurahPagination, React.Dispatch<React.SetStateAction<ISurahPagination>>];
  versesState: [number[], React.Dispatch<React.SetStateAction<number[]>>];
  bookmarkState: [IBookmark[], React.Dispatch<React.SetStateAction<IBookmark[]>>];
  bookmarkCollectionState: [IBookmarkCollection[], React.Dispatch<React.SetStateAction<IBookmarkCollection[]>>];
  searchedBookmarkCollectionState: [IBookmarkCollection[], React.Dispatch<React.SetStateAction<IBookmarkCollection[]>>];
  highlightState: [IHighlight[], React.Dispatch<React.SetStateAction<IHighlight[]>>];
  noteState: [INote[], React.Dispatch<React.SetStateAction<INote[]>>];
  addOrRemoveHighlight: (key: string, ayat: string) => void;
  getBookmarkCollection: (params?: IBookmarkCollectionQuery) => any;
  getHighlight: () => any;
  getAllBookmarks: () => any;
  getAllNotes: () => any;
}

const bookmarkService = new BookmarkService();
const noteService = new NoteService();
const highlightService = new HighlightService();

export const AppContext = createContext<IAppContextState | null>(null);

function App() {
  const { i18n } = useTranslation();

  const storageService = new StorageService();
  const authService = new AuthService();
  const surahService = new SurahService();

  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<IUser | null>(null);

  const [langCode, setLangCode] = useState(storageService.getLanguageCode() ?? ELanguageCode.ENGLISH);
  const [bookmarks, setBookmarks] = useState<IBookmark[]>([]);
  const [bookmarkCollections, setBookmarkCollections] = useState<IBookmarkCollection[]>([]);
  const [searchedBookmarkCollections, setSearchedBookmarkCollections] = useState<IBookmarkCollection[]>(
    () => bookmarkCollections
  );

  const [notes, setNotes] = useState<any[]>([]);
  const [highlights, setHighlights] = useState<any[]>([]);
  const [isOpenSidebar, setIsOpenSidebar] = useState(storageService.getIsOpenSidebar() ?? false);
  const [isCollapsedSidebar, setIsCollapsedSidebar] = useState(storageService.getIsCollapseSidebar() ?? false);
  const [isReadingMode, setIsReadingMode] = useState(storageService.getIsReadingMode() ?? false);
  const [isActiveDarkMode, setIsActiveDarkMode] = useState(storageService.getIsDarkModeActivated() ?? false);

  const [loadingSurah, setLoadingSurah] = useState(false);
  const [surah, setSurah] = useState<ISurahPagination>({
    surahs: [],
    total_counts: 0,
  });
  const [verses, setVerses] = useState<number[]>([]);
  const [books, setBooks] = useState<IBookPagination>({
    books: [],
    total_counts: 0,
  });

  const getAllBookmarks = async () => {
    try {
      const bookmarkResponse = await bookmarkService.getBookmark();
      setBookmarks(bookmarkResponse?.data ?? []);
    } catch (error) {}
  };

  const getAllNotes = async () => {
    try {
      const notes = await noteService.getNotes();
      setNotes(notes?.data ?? []);
    } catch (error) {}
  };

  const getAllSurah = async () => {
    try {
      setLoadingSurah(true);
      const surahResponse = await surahService.getAllSurah({ lang_code: langCode });
      setSurah(
        surahResponse?.data ?? {
          surahs: [],
          total_counts: 0,
        }
      );

      setVerses(Array.from({ length: surahResponse?.data?.surahs?.[0]?.total_ayat }, (_, index) => index + 1) ?? []);
      setLoadingSurah(false);
    } catch (error) {
      setLoadingSurah(false);
      console.warn(error);
    }
  };

  const removeHighlight = async (highlight: IHighlight) => {
    try {
      await highlightService.removeHighlight(highlight.id);
      await getHighlight(); //FIXME
      toast.error(`${highlight.word} successfully removed from highlight`);
    } catch (error: any) {
      toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
    }
  };

  const addHighlight = async (payload: any) => {
    try {
      const data = await highlightService.createHighlight(payload);
      if (data) toast.success(`${payload.word} successfully highlighted`);
      await getHighlight(); //FIXME
    } catch (error: any) {
      toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
    }
  };

  const addOrRemoveHighlight = async (word: string, ayat: string) => {
    if (!user) {
      toast.error("Please Login to highlight words");
      return;
    }
    const highlight = highlights.find((highlight: IHighlight) => highlight?.word === word);

    if (highlight) {
      await removeHighlight(highlight);
      await getHighlight();
    } else {
      const payload = {
        word,
        ayat: ayat,
        user: user?.id,
      };
      await addHighlight(payload);
      await getHighlight();
    }
  };

  const getProfile = async () => {
    try {
      const userRes = await authService.getProfile();
      setUser(userRes);
    } catch (error) {
      console.error("getProfile: ", error);
    }
  };

  const getHighlight = async () => {
    try {
      const res = await highlightService.getHighlight();
      setHighlights(res?.data ?? []);
    } catch (error) {
      console.error("getBookmarkCollection error: ", error);
    }
  };

  const getBookmarkCollection = async (params?: IBookmarkCollectionQuery) => {
    try {
      const res = await bookmarkService.getBookmarkCollection(params);
      setBookmarkCollections(res?.data ?? []);
      setSearchedBookmarkCollections(res?.data ?? []);
      return res?.data;
    } catch (error) {
      console.error("getBookmarkCollection error: ", error);
    }
  };

  useEffect(() => {
    darkToggle(isActiveDarkMode);
  }, []);

  useEffect(() => {
    getAllSurah();
    i18n.changeLanguage(langCode);
    return () => {
      setSurah({
        surahs: [],
        total_counts: 0,
      });
    };
  }, [langCode]);

  useEffect(() => {
    const accessToken = storageService.getAccessToken();
    if (accessToken) {
      setToken(accessToken);
      getProfile();

      if (!bookmarkCollections?.length) {
        getBookmarkCollection();
      }

      if (!highlights?.length) {
        getHighlight();
      }
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <AppContext.Provider
        value={{
          tokenState: [token, setToken],
          userState: [user, setUser],
          isReadingModeState: [isReadingMode, setIsReadingMode],
          isOpenSidebarState: [isOpenSidebar, setIsOpenSidebar],
          isCollapsedSidebarState: [isCollapsedSidebar, setIsCollapsedSidebar],
          langCodeState: [langCode, setLangCode],
          isActiveDarkModeState: [isActiveDarkMode, setIsActiveDarkMode],
          loadingSurahState: [loadingSurah, setLoadingSurah],
          bookState: [books, setBooks],
          surahState: [surah, setSurah],
          versesState: [verses, setVerses],
          bookmarkState: [bookmarks, setBookmarks],
          bookmarkCollectionState: [bookmarkCollections, setBookmarkCollections],
          searchedBookmarkCollectionState: [searchedBookmarkCollections, setSearchedBookmarkCollections],
          highlightState: [highlights, setHighlights],
          noteState: [notes, setNotes],
          addOrRemoveHighlight,
          getBookmarkCollection,
          getHighlight,
          getAllBookmarks,
          getAllNotes,
        }}
      >
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </AppContext.Provider>
    </>
  );
}

export default App;
