import { lazy, Suspense, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Dropdown } from "flowbite-react";

import { AppContext, IAppContextState } from "../../../App";

import { INote } from "../interface/note.interface";
import { IAyat, ISurahDetails } from "../interface/surah.interface";
import { IHighlight } from "../../highlight/interface/highlight.interface";

import { SurahService } from "../service/SurahService";
import { NoteService } from "../../bookmark/service/NoteService";
import { BookmarkService } from "../../bookmark/service/BookmarkService";

import { bismillahText, ignoreSurah } from "../../../shared/constant";

import { flowbiteCustomDropdownThemeConfig } from "../../../shared/config/flowbiteCustomThemeConfig";

import Header from "../../../shared/components/Header";
import SurahDetailsSkeleton from "../skeleton/SurahDetailsSkeleton";

import { MainLayoutContext } from "../../../layout/MainLayout";

const noteService = new NoteService();
const bookmarkService = new BookmarkService();

const AddNoteModal = lazy(() => import("./AddNoteModal"));
const AdvanceCopyModal = lazy(() => import("./AdvanceCopyModal"));
const AddToBookmarkModal = lazy(() => import("../../bookmark/components/AddToBookmarkModal"));
const CopyOrHighlightSelectModal = lazy(() => import("../../../shared/components/CopyOrHighlightSelectModal"));
const CreateBookmarkCategoryModal = lazy(() => import("../../bookmark/components/CreateBookmarkCategoryModal"));

function AyatDetails() {
  const { scrollContainerRef } = useContext(MainLayoutContext);
  const { t } = useTranslation();

  const surahDetailsDefaultValue = {
    total_counts: 0,
    surah: null,
    ayah: [],
  };
  const {
    langCodeState,
    bookmarkState,
    getBookmarkCollection,
    highlightState,
    addOrRemoveHighlight,
    isReadingModeState,
    noteState,
    userState,
    getAllBookmarks,
    getAllNotes,
  } = useContext<IAppContextState | any>(AppContext);

  const { id } = useParams();

  const [bookmarks] = bookmarkState;
  const [user] = userState;
  const [langCode] = langCodeState;
  const [highlights] = highlightState;
  const [isReadingMode] = isReadingModeState;
  const [notes] = noteState;
  const highlighWordMapRef = useRef(new Map());
  const [isWordMapReady, setIsWordMapReady] = useState(false);
  const [isAddingBookmark, setIsAddingBookmark] = useState<boolean[]>([]);

  useEffect(() => {
    highlighWordMapRef.current.clear();
    if (highlights && highlights.length > 0) {
      highlights.forEach((highlight: IHighlight) => {
        const key = `${highlight?.ayat?.surah}_${highlight?.ayat?.id}_${highlight.word}`;
        highlighWordMapRef.current.set(key, highlight.word);
      });
    }
    setIsWordMapReady(true);
  }, [highlights.length]);

  const [loading, setLoading] = useState(false);
  const [markedBookmarkAyat, setMarkedBookmarkAyat] = useState<IAyat | null>(null);

  const [openAddBookmarkModal, setOpenAddBookmarkModal] = useState(false);

  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);

  const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
  const [openCopyOrHighlightModal, setCopyOrHighlightModal] = useState(false);
  const [openAdvanceCopyModal, setOpenAdvanceCopyModal] = useState(false);

  const [addNoteModalData, setAddNoteModalData] = useState<any>(null);
  const [advanceCopyModalData, setAdvanceCopyModalData] = useState<any>({
    surahDetails: {},
    surahId: null,
  });
  const [copyOrHighlightModalData, setCopyOrHighlightModalData] = useState<any>(null);
  const [surahDetails, setSurahDetails] = useState<ISurahDetails>(surahDetailsDefaultValue);

  const surahService = new SurahService();

  const scrollToSection = (sectionId: any) => {
    const container = scrollContainerRef?.current;
    const target = document.getElementById(sectionId); // Access the target section

    if (target) {
      setTimeout(() => {
        container.scrollTo({
          top: target.offsetTop - container.offsetTop,
          behavior: "smooth",
        });
      }, 1000);
    }
  };

  const currentHash = window.location.hash?.replace("#", "");

  useEffect(() => {
    if (currentHash) {
      scrollToSection(currentHash);
    }
  }, [currentHash, surahDetails?.ayah?.length]);

  const copyText = useCallback((text: string, key?: number) => {
    if (key === 0 && id && !ignoreSurah.get(Number(id))) {
      text = bismillahText + "\n" + text;
    }
    navigator.clipboard.writeText(text);
    toast.success(t("message.copy.success"));
  }, []);

  const handleOpenAddNoteModal = useCallback((ayat: IAyat) => {
    setAddNoteModalData(ayat);
    setOpenAddNoteModal(true);
  }, []);

  const handleOpenAdvanceModal = useCallback((data?: any, surahId?: any) => {
    setOpenAdvanceCopyModal(true);
    setAdvanceCopyModalData({ surahDetails: data, surahId });
  }, []);

  const closeAdvanceModal = useCallback(() => {
    setOpenAdvanceCopyModal(false);
    setAdvanceCopyModalData({
      surahDetails: {},
      surahId: null,
    });
  }, []);

  const handleOpenCopyOrHighlightSelectModal = useCallback((word: string, ayatId: number, idx: number) => {
    setCopyOrHighlightModalData({ word, ayatId, idx });
    setCopyOrHighlightModal(true);
  }, []);

  const closeCopyOrHighlightSelectModal = useCallback(() => {
    setCopyOrHighlightModalData(null);
    setCopyOrHighlightModal(false);
  }, []);

  const handleOpenAddBookmarkModal = useCallback(
    (ayat: IAyat) => {
      if (!user) {
        toast.error(t("auth.title.login"));
        return;
      }

      setMarkedBookmarkAyat(ayat);
      setOpenAddBookmarkModal(true);
    },
    [user]
  );

  const addToBookmark = async (collectionId: number) => {
    try {
      if (markedBookmarkAyat?.id) {
        setIsAddingBookmark((prevState) => {
          let newState = [...prevState];
          newState[collectionId] = true;
          return newState;
        });
        const res = await bookmarkService.createBookmark({
          collection: collectionId,
          user: user.id,
          ayat: markedBookmarkAyat.id,
        });
        await getAllBookmarks();
        setIsAddingBookmark((prevState) => {
          let newState = [...prevState];
          newState[collectionId] = false;
          return newState;
        });
      }
    } catch (error: any) {
      toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
    }
  };

  const addToNote = useCallback(async (note_text: any, ayat: any, ayat_code: any) => {
    try {
      const res = await noteService.createNote({
        text: note_text,
        user: user.id,
        ayat: ayat,
        ayat_code,
      });

      setOpenAddNoteModal(false);
      await getAllNotes();
      toast.success(`${t("note.messages.addNote")}`);
    } catch (error: any) {
      toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
    }
  }, []);

  const updateNote = useCallback(async (id: any, payload: any) => {
    try {
      await noteService.updateNote(id, payload);
      await getAllNotes();
      setOpenAddNoteModal(false);
      toast.success(`${t("note.messages.updateNote")}`);
    } catch (error: any) {
      toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
    }
  }, []);

  //TODO need refactoring. Duplicate code
  const createCategory = useCallback(async (title: string) => {
    try {
      const collection = await bookmarkService.createBookmarkCollection({ title });
      if (collection && collection.id) {
        await addToBookmark(collection.id);
      }
      await getBookmarkCollection();
      await getAllBookmarks();
      toast.success(`${t("bookmark.messages.collection.add")}`);
    } catch (error: any) {
      toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
    }
  }, []);

  const deleteCategory = useCallback(async (collectionId: number) => {
    try {
      if (collectionId) {
        setIsAddingBookmark((prevState) => {
          let newState = [...prevState];
          newState[collectionId] = true;
          return newState;
        });
        await bookmarkService.removeBookmarkCollection(collectionId);
        await getBookmarkCollection();
        setIsAddingBookmark((prevState) => {
          let newState = [...prevState];
          newState[collectionId] = false;
          return newState;
        });
        toast.success(`${t("bookmark.messages.collection.delete")}`);
      }
    } catch (error: any) {
      toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
    }
  }, []);

  const getSurahDetails = async (id: number) => {
    try {
      setLoading(true);
      const surahDetailsResponse = await surahService.getOneSurah(id, { lang_code: langCode });
      setSurahDetails(surahDetailsResponse?.data ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBookmarks();
    getAllNotes();
  }, []);

  useEffect(() => {
    if (id) {
      getSurahDetails(Number(id));
    }
    return () => {
      setSurahDetails(surahDetailsDefaultValue);
      setMarkedBookmarkAyat(null);
      setAddNoteModalData(null);
    };
  }, [id, langCode]);

  const copyDropdownLabel: HTMLElement | any = (
    <button className="group hover:bg-gold rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400">
      <i className="ri-file-copy-fill group-hover:text-black text-gray-500 text-lg dark:text-gray-400"></i>
    </button>
  );

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {openAdvanceCopyModal && (
          <AdvanceCopyModal
            state={[openAdvanceCopyModal, setOpenAdvanceCopyModal]}
            data={advanceCopyModalData}
            closeAction={closeAdvanceModal}
          />
        )}
        {openCopyOrHighlightModal && (
          <CopyOrHighlightSelectModal
            closeAction={closeCopyOrHighlightSelectModal}
            state={[openCopyOrHighlightModal, setCopyOrHighlightModal]}
            data={copyOrHighlightModalData}
          />
        )}
        {openAddNoteModal && (
          <AddNoteModal
            saveNote={(note_text: any, ayat: any, ayat_code: any) => {
              addToNote(note_text, ayat, ayat_code);
            }}
            updateNote={(id: number, payload: any) => {
              updateNote(id, payload);
            }}
            data={addNoteModalData}
            state={[openAddNoteModal, setOpenAddNoteModal]}
          />
        )}
        {openAddCategoryModal && (
          <CreateBookmarkCategoryModal
            state={[openAddCategoryModal, setOpenAddCategoryModal]}
            clickAction={(title: string) => createCategory(title)}
          />
        )}
        {openAddBookmarkModal && (
          <AddToBookmarkModal
            markedBookmarkAyat={markedBookmarkAyat}
            isAddingBookmark={isAddingBookmark}
            clickAction={(collectionId: number) => {
              addToBookmark(collectionId);
              getBookmarkCollection();
            }}
            deleteAction={(collectionId: number) => {
              deleteCategory(collectionId);
            }}
            state={[openAddBookmarkModal, setOpenAddBookmarkModal, setOpenAddCategoryModal]}
          />
        )}
      </Suspense>
      <Header>
        {loading ? (
          <SurahDetailsSkeleton />
        ) : (
          <div className="mx-auto px-3 md:max-w-[600] xl:max-w-[1100px] 2xl:max-w-[1400px]">
            <div className="text-center pt-12 pb-16">
              <p className="text-[3rem] font-arabic font-bold dark:text-gray-200">
                {surahDetails?.surah?.surah_name_arabic}
              </p>
              <p className="text-[1.15rem] dark:text-gray-300">{surahDetails?.surah?.title_translation}</p>
              {Number(id) !== 1 && Number(id) !== 9 && (
                <p className="text-[2rem] dark:text-gray-300 font-arabic mt-3">
                  بِسۡمِ اللّٰہِ الرَّحۡمٰنِ الرَّحِیۡمِ
                </p>
              )}
            </div>
            {surahDetails?.ayah?.map((item: IAyat, key: number) =>
              isReadingMode ? (
                <div id={`ayat_${surahDetails.surah?.id}_${item.ayat_code}`} key={`ayat_list_${key}`}>
                  <p className="font-arabic flex justify-center items-center text-[1.5rem] mb-8">
                    <div className="text-center flex flex-wrap flex-row-reverse">
                      {item.ayat.split(" ").map((word, index) => (
                        <span
                          key={`arabic_word_${item.id}_${index}`}
                          onClick={() => addOrRemoveHighlight(word, item.id)}
                          className={`${
                            highlights.includes(word)
                              ? "bg-yellow-400 text-white font-bold hover:text-white"
                              : "hover:text-yellow-400"
                          } dark:text-gray-300 mx-2 cursor-pointer text-[2rem] arabic-bengali`}
                        >
                          {word}
                        </span>
                      ))}
                    </div>
                  </p>
                </div>
              ) : (
                <div
                  key={`ayat_list_${key}`}
                  className="border-b border-gray-300 border-dashed dark:border-gray-500 last:border-0"
                >
                  <div id={`ayat_${key + 1}`} className="flex justify-between mb-8 mt-6 flex-col-reverse lg:flex-row">
                    <ul className="w-[5%] flex lg:block mb-4 lg:mb-0 mt-6 lg:mt-0">
                      <li className="lg:mb-3 me-2 mt-1">
                        <span className="dark:text-gray-400 dark:bg-gray-600 px-2 py-1 rounded-lg dark:font-bold text-xs text-nowrap">
                          <span className="dark:font-bold">{item.ayat_code}</span>
                        </span>
                      </li>
                      <li className="lg:mb-3 me-2">
                        <button className="group hover:bg-gold dark:hover:bg-yellow-400 rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400">
                          <i className="ri-play-fill group-hover:text-black text-gray-500 text-lg dark:text-gray-400"></i>
                        </button>
                      </li>
                      <li className="lg:mb-3 me-2">
                        <button
                          onClick={() => handleOpenAddBookmarkModal(item)}
                          className={`${
                            bookmarks.find((bookmark: any) => bookmark.ayat.ayat_code === item.ayat_code)
                              ? "bg-yellow-400 hover:bg-yellow-500"
                              : " dark:hover:bg-yellow-400 dark:shadow-yellow-400"
                          } group rounded-full w-[34px] h-[34px] shadow`}
                        >
                          <i
                            className={`${
                              bookmarks.find((bookmark: any) => bookmark.ayat.ayat_code === item.ayat_code)
                                ? "text-white "
                                : "text-gray-500 dark:text-gray-400 group-hover:text-black"
                            } ri-bookmark-fill text-lg`}
                          ></i>
                        </button>
                      </li>
                      <li className="lg:mb-3 me-2">
                        <button
                          onClick={() => handleOpenAddNoteModal(item)}
                          className={`${
                            notes.find((note: INote) => note.ayat_code === item.ayat_code)
                              ? "bg-yellow-400 hover:bg-yellow-500"
                              : "hover:bg-gold dark:hover:bg-yellow-400 dark:shadow-yellow-400"
                          } group rounded-full w-[34px] h-[34px] shadow`}
                        >
                          <i
                            className={`${
                              notes.find((note: INote) => note.ayat_code === item.ayat_code)
                                ? "text-white"
                                : "text-gray-500 dark:text-gray-400 group-hover:text-black"
                            } ri-sticky-note-add-fill text-lg`}
                          ></i>
                        </button>
                      </li>
                      <li className="lg:mb-3 me-2">
                        <Dropdown
                          label=""
                          renderTrigger={() => copyDropdownLabel}
                          theme={flowbiteCustomDropdownThemeConfig}
                        >
                          <Dropdown.Item
                            onClick={() =>
                              copyText(`
${item.ayat}
${item.translation.ayat_translation}
`)
                            }
                          >
                            {t("action.copy_all")}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => copyText(item.ayat, key)}>
                            {t("action.copy_arabic")}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => copyText(item.translation.ayat_translation)}>
                            {t("action.copy_translation")}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleOpenAdvanceModal(surahDetails, id)}>
                            {t("action.advance_copy")}
                          </Dropdown.Item>
                        </Dropdown>
                      </li>
                    </ul>
                    <div className="w-[95%]">
                      <div className="text-end flex flex-wrap flex-row-reverse mb-3">
                        {isWordMapReady &&
                          item.ayat.split(" ").map((word, index) => {
                            const highlightWord = highlighWordMapRef.current.get(
                              `${surahDetails.surah?.id}_${item.id}_${word}`
                            );
                            const className = `${
                              highlightWord === word ? "bg-yellow-400 text-white font-bold" : "hover:text-yellow-400"
                            } font-arabic dark:text-gray-300 mx-2 cursor-pointer text-[2rem]`;

                            return (
                              <span
                                key={`arabic_word_${item.id}_${index}`}
                                onClick={() => {
                                  handleOpenCopyOrHighlightSelectModal(word, item.id, index);
                                }}
                                className={className}
                              >
                                {word}
                              </span>
                            );
                          })}
                      </div>

                      <div className="text-start">
                        <p className="dark:text-gray-300 flex flex-wrap">
                          {isWordMapReady
                            ? item?.translation?.ayat_translation?.split(" ").map((word, index) => {
                                const highlightWord = highlighWordMapRef.current.get(
                                  `${surahDetails.surah?.id}_${item.id}_${word}`
                                );
                                const className = `${
                                  highlightWord === word ? "bg-yellow-400 text-white font-bold" : ""
                                } mx-1 cursor-pointer`;
                                return (
                                  <span
                                    key={`ayat_translation_word_${item.id}_${index}`}
                                    onClick={() => handleOpenCopyOrHighlightSelectModal(word, item.id, index)}
                                    className={className}
                                  >
                                    {word}
                                  </span>
                                );
                              })
                            : ""}
                        </p>
                        {item?.translator_name ? (
                          <small className="text-gray-400">
                            translated by : <span className="text-yellow-400">{item?.translator_name}</span>
                          </small>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </Header>
    </>
  );
}

export default AyatDetails;
