import { createBrowserRouter, Outlet } from "react-router-dom";

import Donate from "../modules/donate/components/Donate";
import BookList from "../modules/books/components/BookList";
import BookDetails from "../modules/books/components/BookDetail";
import SurahList from "../modules/home/components/SurahList";
import AyatDetails from "../modules/home/components/AyatDetails";
import SearchAyat from "../modules/home/components/SearchAyat";
import PrivacyPolicy from "../shared/components/PrivacyPolicy";
import Highlight from "../modules/highlight/components/Highlight";
import BookmarkDetails from "../modules/bookmark/components/BookmarkDetails";
import BookmarkCollection from "../modules/bookmark/components/BookmarkCollection";
import Note from "../modules/note/components/Note";
import Login from "../modules/auth/components/Login";
import Register from "../modules/auth/components/Register";
import SetNewPassword from "../modules/auth/components/SetNewPassword";
import ForgotPassword from "../modules/auth/components/ForgotPassword";
import Profile from "../modules/profile/Profile";

import MainLayout from "../layout/MainLayout";
import AuthLayout from "../layout/AuthLayout";
import CommonLayout from "../layout/CommonLayout";
import AuthGuard from "../shared/guards/AuthGuard";
import NotAuthGuard from "../shared/guards/NotAuthGuard";
import DonationCancel from "../modules/donate/cancel";
import DonationSuccess from "../modules/donate/success";
import ProfileDelete from "../modules/profile/ProfileDelete";
import ChangePassword from "../modules/profile/ChangePassword";

// Routes

export const router = createBrowserRouter([
  {
    path: "auth",
    element: (
      <NotAuthGuard>
        <AuthLayout />
      </NotAuthGuard>
    ),
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "set-new-password",
        element: <SetNewPassword />,
      },
    ],
  },
  {
    path: "profile",
    element: (
      <AuthGuard>
        <CommonLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Profile />,
      },
      // {
      //   path: "change-password",
      //   element: <ChangePassword />,
      // },
      {
        path: "delete",
        element: <ProfileDelete />,
      },
    ],
  },
  {
    path: "",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <SurahList />,
      },
      {
        path: "surah/:id",
        element: <AyatDetails />,
      },
      {
        path: "donate",
        element: <Donate />,
      },
      {
        path: "success",
        element: <DonationSuccess />,
      },
      {
        path: "cancel",
        element: <DonationCancel />,
      },
      {
        path: "highlight",
        element: (
          <AuthGuard>
            <Highlight />
          </AuthGuard>
        ),
      },
      {
        path: "note",
        element: (
          <AuthGuard>
            <Note />
          </AuthGuard>
        ),
      },
      {
        path: "search",
        element: <SearchAyat />,
      },
      {
        path: "books",
        children: [
          {
            path: "",
            element: <BookList />,
          },
          {
            path: ":id",
            element: <BookDetails />,
          },
        ],
      },
      {
        path: "bookmarks",
        element: (
          <AuthGuard>
            <Outlet />
          </AuthGuard>
        ),
        children: [
          {
            path: "",
            element: <BookmarkCollection />,
          },
          {
            path: ":collectionId",
            element: <BookmarkDetails />,
          },
        ],
      },
    ],
  },
  {
    path: "privacy-policy",
    element: <AuthLayout />,
    children: [{ path: "", element: <PrivacyPolicy /> }],
  },
]);
