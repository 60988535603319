import { memo } from "react";
import { Link } from "react-router-dom";

function BrandLogo() {
  return (
    <Link to={"/"}>
      <div className="flex items-center justify-between">
        <div className="flex items-center ps-2.5">
          <img src="/images/logo.png" className="h-6 me-3 sm:h-7" alt="Quran Logo" />
          <div>
            <span className="block self-center text-xl font-bold whitespace-nowrap dark:text-gray-300">Quran</span>
            <small className="block dark:text-gray-500">Real Translation</small>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default memo(BrandLogo);
