import { lazy, Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { AppContext, IAppContextState } from "../../../App";

import { IBookmarkCollection } from "../interface/bookmark.interface";

import { BookmarkService } from "../service/BookmarkService";

import BookmarkCollectionSkeleton from "../skeleton/BookmarkCollectionSkeleton";
import ShareButtonSkeleton from "../skeleton/ShareButtonSkeleton";

const ShareButton = lazy(() => import("./ShareButton"));
const ConfirmModal = lazy(() => import("../../../shared/components/ConfirmModal"));
const CreateBookmarkCategoryModal = lazy(() => import("./CreateBookmarkCategoryModal"));

function BookmarkCollection() {
  const bookmarkService = new BookmarkService();
  const { t } = useTranslation();

  const { bookmarkCollectionState, getBookmarkCollection } = useContext<IAppContextState | any>(AppContext);

  const [bookmarkCollections] = bookmarkCollectionState;

  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const openModalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useMemo(
    () => [openModal, setOpenModal],
    [openModal, setOpenModal]
  );

  const [openConfirmModal, setOpenConfrimModal] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState<number>();
  const [searchedBookmarkCollections, setSearchedBookmarkCollections] =
    useState<IBookmarkCollection[]>(bookmarkCollections);

  const confirmModalText = useMemo(() => t("bookmark.messages.collection.confirm"), []);

  const handleBookmarkSearch = async (searchText: string) => {
    const list = await getBookmarkCollection({ title: searchText });
    setSearchedBookmarkCollections(list ?? []);
  };

  const createCategory = useCallback(async (title: string) => {
    try {
      await bookmarkService.createBookmarkCollection({ title });
      await getBookmarkCollection();
      toast.success(`${t("bookmark.messages.collection.add")}`);
    } catch (error: any) {
      toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
    }
  }, []);

  const handleOpenConfirmModal = useCallback((collectionId: number) => {
    setConfirmModalData(collectionId);
    setOpenConfrimModal(true);
  }, []);

  const deleteCategory = useCallback(async (collectionId: number) => {
    try {
      if (collectionId) {
        await bookmarkService.removeBookmarkCollection(collectionId);
        await getBookmarkCollection();
        toast.success(`${t("bookmark.messages.collection.delete")}`);
      }
    } catch (error: any) {
      toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getBookmarkCollection();
    setLoading(false);
  }, []);

  useEffect(() => {
    setSearchedBookmarkCollections(bookmarkCollections);
    return () => {
      setSearchedBookmarkCollections([]);
    };
  }, [bookmarkCollections.length]);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {openConfirmModal && (
          <ConfirmModal
            text={confirmModalText}
            data={confirmModalData}
            clickAction={(collectionId) => deleteCategory(collectionId)}
            state={[openConfirmModal, setOpenConfrimModal]}
          />
        )}
        {openModal && (
          <CreateBookmarkCategoryModal state={openModalState} clickAction={(title: string) => createCategory(title)} />
        )}
      </Suspense>
      <h1 className="border-b-2 border-yellow-400 border-opacity-10 text-center font-normal text-[2rem] py-8 dark:text-yellow-400">
        {t("bookmark.title")}
      </h1>

      <div className="flex items-center justify-between mt-4">
        <div>
          <div className="max-w-xl mx-auto my-12 px-3 md:px-0">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                <i className="ri-search-line text-xl dark:text-gray-400"></i>
              </div>
              <input
                onBlur={(e) => handleBookmarkSearch(e.target.value)}
                type="text"
                id="bookmark_search_input"
                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-full focus:ring-yellow-400 focus:border-yellow-400 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-400 dark:focus:border-yellow-400 shadow"
                placeholder={t("placeholder.searchBookmark")}
              />
            </div>
          </div>
        </div>
        <button onClick={() => setOpenModal(true)} className="bg-yellow-400 text-white rounded-full px-12 p-3">
          {t("bookmark.create")}
        </button>
      </div>
      {loading ? (
        <BookmarkCollectionSkeleton />
      ) : searchedBookmarkCollections?.length ? (
        <div className="surah-container flex flex-wrap">
          {bookmarkCollections.map((item: IBookmarkCollection, key: number) => (
            <div
              key={`bookmark_collection_${key}`}
              className="px-2 xl:w-[33.33%] lg:w-[50%] md:w-[50%] w-[100%] h-[100%]"
            >
              <Link to={`/bookmarks/${item.id}`}>
                <div className="mb-3 border border-gray-200 px-4 py-8 rounded-xl group hover:shadow-[0_9px_15px_0px_rgba(0,0,0,0.1)] hover:border-[rgba(202,138,4,0.25)] dark:border-gray-600 dark:hover:border-[rgba(202,138,4,0.25)] flex center items-center cursor-pointer dark:bg-gray-800">
                  <div className="w-[20%]">
                    <span className="inline-block w-[48px] text-center py-2 md:py-3 rounded-xl shadow group-hover:bg-yellow-400 group-hover:text-white dark:text-gray-400 dark:shadow-gray-600">
                      {key + 1}
                    </span>
                  </div>
                  <div className="w-[70%]">
                    <p className="group-hover:text-yellow-400 dark:text-gray-200">{item.title}</p>
                    <p className="text-sm dark:text-gray-400">
                      {item?.ayat_count} {t("ayat.label")}
                    </p>
                  </div>
                  <div className="w-[10%]">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenConfirmModal(item.id);
                      }}
                      className="group bg-red-500 hover:bg-red-400 rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400"
                    >
                      <i className="ri-delete-bin-line text-white text-lg"></i>
                    </button>
                    <Suspense fallback={<ShareButtonSkeleton />}>
                      <ShareButton item={{ id: item.id }} handleShareOption={handleOpenConfirmModal} />
                    </Suspense>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p className="my-8 text-center dark:text-gray-300">{t("bookmark.notFoundCollection")}</p>
      )}
    </>
  );
}

export default BookmarkCollection;
