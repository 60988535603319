import { memo, useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Tabs } from "flowbite-react";
import { useTranslation } from "react-i18next";

import { darkToggle, AppContext, IAppContextState } from "../../App";

import { flowbiteCustomTabThemeConfig } from "../config/flowbiteCustomThemeConfig";

import { ISurah } from "../../modules/home/interface/surah.interface";

import { StorageService } from "../service/storageService";

import {
  SurahListSidebarSkeleton,
  SurahListSidebarVerseSkeleton,
} from "../../modules/home/skeleton/SurahListSidebarSkeleton";

function SidebarComponent() {
  const { t } = useTranslation();
  const {
    isOpenSidebarState,
    isCollapsedSidebarState,
    isActiveDarkModeState,
    surahState,
    loadingSurahState,
    versesState,
    isReadingModeState,
  } = useContext<IAppContextState | any>(AppContext);

  const [surah] = surahState;
  const [loadingSurah] = loadingSurahState;
  const [isOpenSidebar, setIsOpenSidebar] = isOpenSidebarState;
  const [isCollapsedSidebar, setIsCollapsedSidebar] = isCollapsedSidebarState;
  const [isReadingMode, setIsReadingMode] = isReadingModeState;

  const [verses, setVerses] = versesState;

  const [isActiveDarkMode, setIsActiveDarkMode] = isActiveDarkModeState;

  const [searchInput, setSearchInput] = useState("");
  const [searchedSurah, setSearchedSurah] = useState<ISurah[]>([]);

  const storageService = new StorageService();

  const handleSurahSearch = (searchText: string) => {
    const text = searchText.toLocaleLowerCase();
    setSearchInput(searchText);
    if (searchText === "") {
      setSearchedSurah(surah?.surahs ?? []);
    } else {
      const afterSearch = surah?.surahs?.filter((s: ISurah) => {
        return (
          s?.surah_name_arabic?.toLocaleLowerCase().includes(text) ||
          s?.title_translation?.toLocaleLowerCase().includes(text) ||
          s?.translation?.toLocaleLowerCase().includes(text) ||
          s?.sl_no?.toString() === searchText
        );
      });
      setSearchedSurah(afterSearch);
    }
  };

  const scrollUp = () => {
    const scrollableDiv = document.getElementById("quranrt_main_content_layout_container");
    const bottomElement = scrollableDiv?.lastElementChild;
    bottomElement?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleSetVerses = (verse: number) => {
    setVerses(Array.from({ length: verse }, (_, index) => index + 1) ?? []);
  };

  const toggleReadingMode = () => {
    storageService.setIsReadingMode(!isReadingMode);
    setIsReadingMode(() => !isReadingMode);
  };

  const toggleDarkMode = () => {
    storageService.setIsDarkModeActivated(!isActiveDarkMode);
    setIsActiveDarkMode(() => !isActiveDarkMode);
    darkToggle(!isActiveDarkMode);
  };

  const toggleSidebarHidden = () => {
    storageService.setIsOpenSidebar(isOpenSidebar);
    storageService.setIsCollapseSidebar(!isCollapsedSidebar);
    setIsOpenSidebar(() => isOpenSidebar);
    setIsCollapsedSidebar(() => !isCollapsedSidebar);
  };

  const toggleSidebar = () => {
    storageService.setIsOpenSidebar(!isOpenSidebar);
    setIsOpenSidebar(!isOpenSidebar);
  };

  useEffect(() => {
    setSearchedSurah(surah?.surahs ?? []);
  }, [surah?.surahs?.length]);

  const downloadFontFile = () => {
    const fileUrl = "/fonts/AlMuslimeenaBengali.ttf";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "AlMuslimeenaBengali.ttf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <button
        className={`w-8 h-8 rounded-full fixed top-[16px] sm:right-[24px] md:right-[16px] flex justify-center items-center block md:hidden bg-white text-gold border border-gray shadow dark:shadow-sm dark:shadow-gray-300 dark:bg-gray-700 dark:border-gray-500 z-[9999]`}
        onClick={toggleSidebarHidden}
      >
        <i className="ri-menu-line"></i>
      </button>
      <aside
        className={`${isOpenSidebar ? "w-[18rem]" : "w-[4.3rem]"} ${
          !isCollapsedSidebar ? "left-0" : "left-[-100%]"
        } duration-200 shadow-end lg:left-0 fixed top-0 z-40 h-screen dark:shadow-gray-700`}
        aria-label="Sidebar"
      >
        <button
          className={`${
            isOpenSidebar ? "left-[17.15rem] top-6" : "left-14 top-9"
          } w-7 h-7 rounded-full absolute flex justify-center items-center bg-white text-gold border border-gray shadow dark:bg-gray-700 dark:border-gray-500`}
          onClick={() => toggleSidebar()}
        >
          <i className={`ri-arrow-left-s-line text-xl ${!isOpenSidebar && "rotate-180"}`}></i>
        </button>
        <div className="h-full px-3 py-4 overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:hover:overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <div className="flex items-start justify-between">
            <Link to="/" className="flex items-center ps-2.5 mb-5">
              <img src="/images/logo.png" className="h-6 me-3 sm:h-7" alt="Quran Logo" />
              <div className={`${!isOpenSidebar ? "scale-0" : ""}`}>
                <span className="block self-center text-xl font-bold whitespace-nowrap dark:text-gray-300">Quran</span>
                <small className="block dark:text-gray-500">Real Translation</small>
              </div>
            </Link>
          </div>
          <ul className="space-y-2 font-medium">
            <li>
              <div>
                <Tabs
                  aria-label="Default tabs"
                  style="underline"
                  theme={flowbiteCustomTabThemeConfig}
                  className={`${isOpenSidebar ? "" : "hidden"}`}
                >
                  <Tabs.Item active title={t("sidebar.surah.title")}>
                    <form className={`${isOpenSidebar ? "max-w-sm mx-auto" : "hidden"} mt-2`}>
                      <div className="relative z-0 w-full mb-5 group">
                        <input
                          type="text"
                          name="floating_surah_name"
                          id="floating_surah_name"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-yellow-400 focus:outline-none focus:ring-0 focus:border-gold peer active:outline-0"
                          placeholder=" "
                          required
                          onChange={(e: any) => handleSurahSearch(e.target.value)}
                          value={searchInput}
                        />
                        <label
                          htmlFor="floating_surah_name"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-focus:dark:text-yellow-400 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          {t("placeholder.searchSurah")}
                        </label>
                      </div>
                    </form>
                    <div
                      className={`${
                        isOpenSidebar ? "" : "hidden"
                      } w-100 border border-gray bg-white rounded-md text-sm overflow-y-auto lg:overflow-y-hidden lg:hover:overflow-y-auto dark:bg-gray-700 dark:border-gray-600`}
                    >
                      <ul>
                        <li className="flex py-2 px-6 dark:bg-gray-700">
                          <div className="w-[80%]">
                            <div className="flex">
                              <span className="w-[25%] font-semibold dark:text-gray-300">
                                {t("sidebar.surah.table.sl")}
                              </span>
                              <span className="w-[75%] font-semibold dark:text-gray-300 ml-3">
                                {t("sidebar.surah.table.name")}
                              </span>
                            </div>
                          </div>
                          <span className="w-[20%] text-end font-semibold dark:text-gray-300">
                            {t("sidebar.surah.table.verse")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="flex mt-3">
                      <div className="w-[70%] me-3 border border-gray-200 rounded-lg h-64 w-100 border border-gray bg-white rounded-md text-sm  overflow-y-auto lg:overflow-y-hidden lg:hover:overflow-y-auto dark:bg-gray-700 dark:border-gray-600">
                        {loadingSurah ? (
                          <SurahListSidebarSkeleton />
                        ) : (
                          searchedSurah?.map((item: ISurah, key: number) => (
                            <NavLink
                              to={`/surah/${item.sl_no}`}
                              onClick={() => handleSetVerses(item.total_ayat)}
                              key={`surah_list_sidebar_${key}`}
                              className={({ isActive }) =>
                                isActive
                                  ? "flex px-3 border-b border-dashed py-2 px-6 dark:bg-gray-700 dark:border-gray-500 dark:hover:text-gold hover:text-gold text-gold cursor-pointer last:border-0"
                                  : "flex px-3 border-b border-dashed py-2 px-6 dark:bg-gray-700 dark:border-gray-500 dark:hover:text-gold hover:text-gold dark:text-gray-400 cursor-pointer last:border-0"
                              }
                            >
                              <span className="w-[25%]">{item.sl_no}</span>
                              <span className="w-[75%]">{item.translation}</span>
                            </NavLink>
                          ))
                        )}
                      </div>
                      <div className="w-[30%] border border-gray-200 rounded-lg h-64 w-100 border border-gray bg-white rounded-md text-sm  overflow-y-auto lg:overflow-y-hidden lg:hover:overflow-y-auto dark:bg-gray-700 dark:border-gray-600">
                        {loadingSurah ? (
                          <SurahListSidebarVerseSkeleton />
                        ) : (
                          verses.map((item: number) => (
                            <p
                              key={`surah_verse_sidebar_verse_${item}`}
                              className="text-center border-b border-dashed py-2 px-6 dark:bg-gray-700 dark:border-gray-500 last:border-0"
                            >
                              <Link to={`#ayat_${item}`}>
                                <a className="dark:hover:text-gold hover:text-gold dark:text-gray-400 cursor-pointer">
                                  {item}
                                </a>
                              </Link>
                            </p>
                          ))
                        )}
                      </div>
                    </div>
                  </Tabs.Item>
                </Tabs>
              </div>
            </li>
            <li>
              <NavLink
                to={"/bookmarks"}
                className={({ isActive }) =>
                  isActive
                    ? `${
                        isOpenSidebar ? "" : "justify-center"
                      } cursor-pointer flex items-center p-2 text-gray-900 bg-gray-100 dark:bg-gray-700 group shadow shadow-gold rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`
                    : `${
                        isOpenSidebar ? "" : "justify-center"
                      } cursor-pointer flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`
                }
              >
                <i
                  className={`${
                    isOpenSidebar ? "" : "text-[1.45rem]"
                  } ri-bookmark-line text-lg text-gray_2 group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200`}
                ></i>
                <div className={`${isOpenSidebar ? "" : "hidden"}`}>
                  <span className="flex-1 ms-3 whitespace-nowrap text-sm group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200">
                    {t("sidebar.menus.bookmark")}
                  </span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/highlight"}
                className={({ isActive }) =>
                  isActive
                    ? `${
                        isOpenSidebar ? "" : "justify-center"
                      } cursor-pointer flex items-center p-2 text-gray-900 bg-gray-100 dark:bg-gray-700 group shadow shadow-gold rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`
                    : `${
                        isOpenSidebar ? "" : "justify-center"
                      } cursor-pointer flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`
                }
              >
                <i
                  className={`${
                    isOpenSidebar ? "" : "text-[1.45rem]"
                  } ri-edit-line text-lg text-gray_2 group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200`}
                ></i>
                <div className={`${isOpenSidebar ? "" : "hidden"}`}>
                  <span className="flex-1 ms-3 whitespace-nowrap text-sm group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200">
                    {t("sidebar.menus.highlight")}
                  </span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/note"}
                className={({ isActive }) =>
                  isActive
                    ? `${
                        isOpenSidebar ? "" : "justify-center"
                      } cursor-pointer flex items-center p-2 text-gray-900 bg-gray-100 dark:bg-gray-700 group shadow shadow-gold rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`
                    : `${
                        isOpenSidebar ? "" : "justify-center"
                      } cursor-pointer flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`
                }
              >
                <i
                  className={`${
                    isOpenSidebar ? "" : "text-[1.45rem]"
                  } ri-sticky-note-add-line text-lg text-gray_2 group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200`}
                ></i>
                <div className={`${isOpenSidebar ? "" : "hidden"}`}>
                  <span className="flex-1 ms-3 whitespace-nowrap text-sm group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200">
                    {t("sidebar.menus.note")}
                  </span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/books"}
                className={({ isActive }) =>
                  isActive
                    ? `${
                        isOpenSidebar ? "" : "justify-center"
                      } cursor-pointer flex items-center p-2 text-gray-900 bg-gray-100 dark:bg-gray-700 group shadow shadow-gold rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`
                    : `${
                        isOpenSidebar ? "" : "justify-center"
                      } cursor-pointer flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`
                }
              >
                <i
                  className={`${
                    isOpenSidebar ? "" : "text-[1.45rem]"
                  } ri-book-2-line text-lg text-gray_2 group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200`}
                ></i>
                <div className={`${isOpenSidebar ? "" : "hidden"}`}>
                  <span className="flex-1 ms-3 whitespace-nowrap text-sm group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200">
                    {t("sidebar.menus.book")}
                  </span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/donate"}
                className={({ isActive }) =>
                  isActive
                    ? `${
                        isOpenSidebar ? "" : "justify-center"
                      } cursor-pointer flex items-center p-2 text-gray-900 bg-gray-100 dark:bg-gray-700 group shadow shadow-gold rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`
                    : `${
                        isOpenSidebar ? "" : "justify-center"
                      } cursor-pointer flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`
                }
              >
                <i
                  className={`${
                    isOpenSidebar ? "" : "text-[1.45rem]"
                  } ri-hand-coin-line text-lg text-gray_2 group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200`}
                ></i>
                <div className={`${isOpenSidebar ? "" : "hidden"}`}>
                  <span className="flex-1 ms-3 whitespace-nowrap text-sm group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200">
                    {t("sidebar.menus.donate")}
                  </span>
                </div>
              </NavLink>
            </li>
            <li>
              <a
                type="button"
                className={`${
                  isOpenSidebar ? "" : "justify-center"
                } cursor-pointer flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`}
                onClick={scrollUp}
              >
                <i
                  className={`${
                    isOpenSidebar ? "" : "text-[1.45rem]"
                  } ri-arrow-up-circle-line text-lg text-gray_2 group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200 dark:group-focus:text-gray-300`}
                ></i>
                <div className={`${isOpenSidebar ? "" : "hidden"}`}>
                  <span className="flex-1 ms-3 whitespace-nowrap text-sm group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200 dark:group-focus:text-gray-300">
                    {t("sidebar.menus.pageTop")}
                  </span>
                </div>
              </a>
            </li>
            <li>
              <a
                href="#page_bottom_container"
                className={`${
                  isOpenSidebar ? "" : "justify-center"
                } cursor-pointer flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`}
              >
                <i
                  className={`${
                    isOpenSidebar ? "" : "text-[1.45rem]"
                  } ri-arrow-down-circle-line text-lg text-gray_2 group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200 dark:group-focus:text-gray-300`}
                ></i>
                <div className={`${isOpenSidebar ? "" : "hidden"}`}>
                  <span className="flex-1 ms-3 whitespace-nowrap text-sm group-hover:text-black group-focus:text-black dark:text-gray-400 dark:group-hover:text-gray-200 dark:group-focus:text-gray-300">
                    {t("sidebar.menus.pageBottom")}
                  </span>
                </div>
              </a>
            </li>
            <li>
              <button
                className={`text-left ${
                  isReadingMode ? "active shadow" : ""
                } relative py-2 rounded-md bg-transparent w-[100%] px-2 hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`}
                onClick={toggleReadingMode}
              >
                <i
                  className={`${
                    isOpenSidebar ? "" : "text-[1.45rem] flex justify-center"
                  } ri-eye-line text-lg text-gray_2 group-hover:text-black dark:text-gray-400 dark:group-hover:text-gray-200`}
                ></i>
                <span
                  className={`${
                    isOpenSidebar ? "" : "hidden"
                  } ms-2 text-sm group-hover:text-black dark:text-gray-400 dark:group-hover:text-gray-200`}
                >
                  {t("sidebar.menus.readingMode")}
                </span>
              </button>
            </li>
            <li>
              <button
                className={`${isOpenSidebar ? "text-left" : "text-center"} ${
                  isActiveDarkMode ? "active shadow" : ""
                } relative py-2 rounded-md bg-transparent w-[100%] px-2 hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`}
                onClick={toggleDarkMode}
              >
                <i
                  className={`${isActiveDarkMode ? "ri-moon-clear-fill" : "ri-moon-clear-line"} ${
                    isOpenSidebar ? "" : "text-[1.45rem]"
                  } text-lg text-gray_2 group-hover:text-black dark:text-gray-400 dark:group-hover:text-gray-200`}
                ></i>
                <span
                  className={`${
                    isOpenSidebar ? "" : "hidden"
                  } ms-2 text-sm group-hover:text-black dark:text-gray-400 dark:group-hover:text-gray-200`}
                >
                  {t("sidebar.menus.darkMode")}
                </span>
                <span
                  className={`${isOpenSidebar ? "" : "hidden"} ${
                    isActiveDarkMode ? "" : "hidden"
                  } absolute right-3 top-[0.65rem]`}
                >
                  <i className="ri-check-double-line"></i>
                </span>
              </button>
            </li>
            <li
              className={`${
                isOpenSidebar ? "" : "justify-center"
              } cursor-pointer flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`}
              onClick={downloadFontFile}
            >
              <img src="/images/download-logo.png" alt={"Download icon"} className="h-6 " />
              <span
                className={`${
                  isOpenSidebar ? "" : "hidden"
                } ms-2 text-sm group-hover:text-black dark:text-gray-400 dark:group-hover:text-gray-200`}
              >
                {t("sidebar.menus.downloadFont")}
              </span>
            </li>

            <NavLink
              to={"https://play.google.com/work/apps/details?id=com.quranrt.android&hl=en"}
              className={() =>
                `${
                  isOpenSidebar ? "" : "justify-center"
                } cursor-pointer flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 group hover:shadow hover:shadow-gold`
              }
            >
              <div className="flex items-center ">
                <img src="/images/play-store-icon.png" alt={"Play store icon"} className="h-6 " />
                <span
                  className={`${
                    isOpenSidebar ? "" : "hidden"
                  }  ms-2 text-sm group-hover:text-black dark:text-gray-400 dark:group-hover:text-gray-200`}
                >
                  {t("sidebar.menus.download")}
                </span>
              </div>
            </NavLink>
          </ul>
        </div>
      </aside>
    </>
  );
}

export default memo(SidebarComponent);
