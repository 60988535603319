import { createContext, useContext, useRef } from "react";
import { Outlet } from "react-router-dom";

import { AppContext, IAppContextState } from "../App";

import Footer from "../shared/components/Footer";
import Navbar from "../shared/components/Navbar";
import BrandLogo from "../shared/components/BrandLogo";
import SidebarComponent from "../shared/components/Sidebar";

export const MainLayoutContext = createContext<any>(null);

function MainLayout() {
  const { isOpenSidebarState, isCollapsedSidebarState } = useContext<IAppContextState | any>(AppContext);
  const [isOpenSidebar] = isOpenSidebarState;
  const [isCollapsedSidebar] = isCollapsedSidebarState;

  const scrollContainerRef = useRef<any>();

  return (
    <MainLayoutContext.Provider value={{ scrollContainerRef }}>
      <SidebarComponent />
      <div
        ref={scrollContainerRef}
        id="quranrt_main_content_layout_container"
        className={`${
          isOpenSidebar ? "lg:ml-[18rem]" : isCollapsedSidebar ? "ml-0" : "ml-[4.3rem]"
        } position-absolute lg:position-static dark:bg-gray-600 h-screen overflow-auto`}
      >
        <div className="flex flex-col w-full h-full justify-between">
          <div>
            <Navbar>
              <BrandLogo />
            </Navbar>
            <div className="px-4 pt-4 pb-2">
              <div className="w-[100%] rounded-lg bg-white border border-gray-300 dark:bg-gray-700 dark:border-yellow-400 p-4">
                <Outlet />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </MainLayoutContext.Provider>
  );
}

export default MainLayout;
