import { Outlet } from "react-router";
import { memo } from "react";

import Footer from "../shared/components/Footer";
import BrandLogo from "../shared/components/BrandLogo";
import Navbar from "../shared/components/Navbar";

const AuthLayout = memo(function AuthLayout() {
  return (
    <>
      <div className="min-h-full h-full max-h-max">
        <div className="auth_background md:flex md:flex-col h-full md:justify-between overflow-auto">
          <Navbar>
            <BrandLogo />
          </Navbar>
          <div>
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
});

export default AuthLayout;
