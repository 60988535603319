import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Pagination } from "flowbite-react";

import { PAGE_SIZE_10 } from "../../../shared/constant/pagination.constant";
import { flowbiteCustomPaginationThemeConfig } from "../../../shared/config/flowbiteCustomThemeConfig";

import { SurahService } from "../service/SurahService";

import { ISearchAyat } from "../interface/surah.interface";
import { IPaginationParams } from "../../../shared/interface/pagination.interface";

import SearchListSkeleton from "../skeleton/SearchListSkeleton";
import { SEARCH_SPLIT_REGEX } from "../../../shared/constant/regex.constant";

function SearchAyat() {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchText = searchParams.get("search_text");

  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [ayahs, setAyahs] = useState<ISearchAyat[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const onPageChange = (page: number) => {
    setCurrentPage(() => page);
    getSearchList({ page_size: PAGE_SIZE_10, page: page, search: searchText ?? "" });
  };

  const navigate = useNavigate();

  const surahService = new SurahService();

  const handleKeyPressSearch = (event: any) => {
    if (event.key === "Enter") {
      setSearchParams({ search_text: searchInput });
      navigate(`/search?search_text=${encodeURIComponent(searchInput)}`);
    }
  };

  const getSearchList = async (params?: IPaginationParams) => {
    try {
      setLoading(true);
      const searchResponse = await surahService.search(params);
      setAyahs(searchResponse?.data?.ayahs ?? []);
      setTotalCount(searchResponse?.data?.total_counts ?? 0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getSearchList({ page_size: PAGE_SIZE_10, page: currentPage, search: searchText ?? "" });
    setSearchInput(searchText ?? "");
    return () => {
      setAyahs([]);
      setTotalCount(0);
    };
  }, [searchText]);

  return (
    <>
      <div>
        <div>
          <div>
            <div className="w-full mx-auto my-12 px-3 md:px-0">
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                  <i className="ri-search-line text-xl dark:text-gray-400"></i>
                </div>
                <input
                  onKeyDown={(e: any) => handleKeyPressSearch(e)}
                  type="text"
                  id="email-address-icon"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-full focus:ring-yellow-400 focus:border-yellow-400 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-400 dark:focus:border-yellow-400 shadow"
                  placeholder="Search here"
                  onChange={(e: any) => setSearchInput(e.target.value)}
                  value={searchInput}
                />

                <button
                  onClick={() => setSearchInput("")}
                  className="absolute inset-y-0 end-4 flex items-center ps-3.5"
                >
                  <i className="ri-close-line text-xl px-1 rounded-full text-danger_light hover:text-danger"></i>
                </button>
              </div>
            </div>
          </div>
          <div>
            {totalCount ? (
              <p className="dark:text-gray-200">
                <small>Search results: {totalCount}</small>
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        {loading ? (
          <SearchListSkeleton />
        ) : (
          <>
            {ayahs?.length ? (
              ayahs?.map((item: ISearchAyat, key: number) => {
                const ayatCodeSplits = item?.ayat_code?.split(":") ?? null;
                const ayatNumber = ayatCodeSplits?.[1] ? `#ayat_${ayatCodeSplits?.[1]}` : null;
                return (
                  <div
                    id={`ayat_search_${item.id}`}
                    key={`ayat_list_search_${key}`}
                    className="border-b border-gray-300 border-dashed dark:border-gray-500 last:border-0"
                  >
                    <div className="lg:flex justify-between mb-8 mt-6">
                      <div className="w-[95%]">
                        <div className="text-start">
                          <Link
                            to={ayatNumber ? `/surah/${item?.surah_sl_no}${ayatNumber}` : `/surah/${item?.surah_sl_no}`}
                          >
                            <>
                              <a className={`text-base block mb-1`}>
                                {item?.ayat_translation_english
                                  ?.split(SEARCH_SPLIT_REGEX)
                                  ?.map((engWord: string, key: number) => {
                                    return (
                                      <span
                                        key={`search_ayat_engligh_${key}`}
                                        className={`${
                                          searchText?.includes(engWord)
                                            ? "bg-yellow-200 dark:bg-yellow-200"
                                            : "text-blue-500 dark:text-gray-400 dark:hover:text-gray-300"
                                        } mr-1`}
                                      >
                                        {engWord}
                                      </span>
                                    );
                                  })}
                              </a>
                              <a className="text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 text-xs block">
                                {item?.ayat_translation_bengali
                                  ?.split(SEARCH_SPLIT_REGEX)
                                  ?.map((engWord: string, key: number) => {
                                    return (
                                      <span
                                        key={`search_ayat_engligh_${key}`}
                                        className={`${
                                          searchText?.includes(engWord)
                                            ? "bg-yellow-200 dark:bg-yellow-200 rounded-sm"
                                            : "text-blue-500 dark:text-gray-400 dark:hover:text-gray-300"
                                        } mr-1`}
                                      >
                                        {engWord}
                                      </span>
                                    );
                                  })}
                              </a>
                            </>
                          </Link>
                          <div>
                            {item?.translator_name ? (
                              <small className="text-gray-400">
                                Translated by : <span className="text-yellow-400">{item?.translator_name}</span>
                              </small>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-center my-3">No data found</p>
            )}
          </>
        )}

        {!loading && totalCount > PAGE_SIZE_10 && (
          <div className="flex overflow-x-auto sm:justify-center mb-6">
            <Pagination
              theme={flowbiteCustomPaginationThemeConfig}
              layout="pagination"
              currentPage={currentPage}
              totalPages={Math.round(totalCount / PAGE_SIZE_10) + 1}
              onPageChange={onPageChange}
              showIcons
            />
          </div>
        )}
      </div>
    </>
  );
}

export default SearchAyat;
